import React from "react"

// Libraries
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

// Components
import Container from "components/container"
import BlogCard from "components/blog-card"

// Utils
import { colors } from "utils/variables"
import breakpoint from "utils/breakpoints"

const StyledEthicsPosts = styled.section`
  background-color: ${colors.silver__extra_pale};

  .h1 {
    margin-top: 56px;
    margin-bottom: 56px;
  }

  .col-md-4 {
    padding-right: 0;
    padding-left: 0;

    ${breakpoint.small`
     padding-right: calc(var(--bs-gutter-x) * 0.67);
     padding-left: calc(var(--bs-gutter-x) * 0.67);
    `}
  }

  .cards-container {
    margin: auto;
    margin-bottom: 120px;
  }
`

const EthicsBlogPosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPosts(
        filter: {
          blogCategories: {
            elemMatch: { id: { eq: "844b23ce-8a1a-5bfa-9c5d-5172bc35d40b" } }
          }
        }
        sort: { order: DESC, fields: createdAt }
        limit: 3
      ) {
        edges {
          node {
            id
            title
            slug
            excerpt {
              excerpt
            }
            featuredImage {
              gatsbyImageData(width: 352, height: 200)
            }
            blogCategories {
              title
              slug
            }
          }
        }
      }
    }
  `)
  return (
    <StyledEthicsPosts>
      <Container>
        <div className="row">
          <h1 className="h1 text-center color-navy__extra_dark">
            Read Our Latest Pieces on Ethics
          </h1>
          <div className="row cards-container">
            {data.allContentfulBlogPosts.edges.map((post) => (
              <div key={post.node.id} className="col-md-4 mb-4">
                <BlogCard {...post.node} external />
              </div>
            ))}
          </div>
        </div>
      </Container>
    </StyledEthicsPosts>
  )
}

export default EthicsBlogPosts
