import React from "react"

// Libraries
import styled from "styled-components"

// Components
import Container from "components/container"

// Utils
import { colors } from "utils/variables"
import breakpoint from "utils/breakpoints/"

// Icons
import IconConsideration from "assets/icons/ethics/icon-consideration.inline.svg"

const StyledEthicalConsideration = styled.section`
  .container {
    margin: 40px 16px 64px 16px;
    padding: 24px;
    background-color: ${colors.silver__extra_pale};
    border-radius: 20px;
    position: relative;

    ${breakpoint.small`
    margin: 101px auto 172px auto;
    padding: 96px;
    `}

    .icon-svg {
      display: none;
      position: absolute;
      left: -13px;
      bottom: -32px;

      ${breakpoint.medium`
      display: block;
      `}
    }
  }

  h2 {
    font-size: 32px;
    line-height: 45px;
  }
`

const EthicalConsideration = () => (
  <Container>
    <StyledEthicalConsideration className="row justify-content-center align-items-center">
      <div className="container col-12 col-sm-10 text-left text-sm-center">
        <p className="h2 mb-4">
          Ethical considerations related to the impact of our technology on
          members of the workforce have always been foundational to our mission
          at Arena Analytics.
        </p>
        <p className="text--large text-left text-sm-center">
          That’s why we created an AI Ethics Advisory board of distinguished
          industry experts, academics, and thought leaders who provide guidance
          and direction to help us act responsibly and ethically when developing
          and deploying our solutions.
        </p>
        <IconConsideration className="icon-svg" />
      </div>
    </StyledEthicalConsideration>
  </Container>
)

export default EthicalConsideration
