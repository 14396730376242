import React from "react"

// Libraries
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

// Components
import Container from "components/container/"
import TeamMemberCard from "components/team-member-card"

// Utils
import { colors } from "utils/variables"
import breakpoints from "utils/breakpoints"

const StyledAiEthicsBoard = styled.section`
  padding: 64px 0 184px 0;
  background: ${colors.silver__pale};

  ${breakpoints.medium`
    padding: 99px 0 120px 0;
  `}

  span {
    color: ${colors.turquoise__primary};
  }

  .title {
    margin-bottom: 39px;
    ${breakpoints.medium`
      margin-bottom: 96px;
    `}
  }

  .col-12 {
    ${breakpoints.medium`
      padding-right: 16px;
      padding-left: 16px;
    `}
  }
`

const AiEthicsBoard = () => {
  const {
    aiEthicsMembers: { aiEthicsMembers },
  } = useStaticQuery(graphql`
    query {
      aiEthicsMembers: contentfulEthics(title: { eq: "Ethics" }) {
        aiEthicsMembers {
          ...aiEthicsMember
        }
      }
    }
  `)

  return (
    <StyledAiEthicsBoard>
      <Container>
        <h1 className="h1 text-center title d-md-none">
          Meet our <span>AI Ethics Board</span>
        </h1>
        <h1 className="h2 maxi text-center title d-none d-md-block">
          Meet our <span>AI Ethics Board</span>
        </h1>
        <div className="row">
          {aiEthicsMembers.map((teamMember) => (
            <div className="col-12 col-sm-4 mb-4" key={teamMember.fullName}>
              <TeamMemberCard {...teamMember} />
            </div>
          ))}
        </div>
      </Container>
    </StyledAiEthicsBoard>
  )
}

export default AiEthicsBoard
