import React from "react"

// Libraries
import styled from "styled-components"
import { transparentize } from "polished"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"

// Icons
import IconStage from "assets/icons/ethics/icon-stage.svg"

const StyledEthicsApproach = styled.section`
  padding: 64px 0;

  ${breakpoint.medium`
    padding: 324px 0 154px 0;
  `}

  .h2 {
    margin-bottom: 24px;

    ${breakpoint.medium`
      margin-bottom: 94px;
    `}
  }

  .col-12:last-child {
    .stage {
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  .stage {
    position: relative;
    display: flex;

    ${breakpoint.small`
      flex-direction: column;
    `}

    &::before {
      content: url("${IconStage}");
      width: 29px;
      height: 29px;
      display: block;
      position: relative;
      top: calc((76px - 30px) / 2);
      margin-right: 8px;
      z-index: 1;

      ${breakpoint.small`
        top: 0;
        margin: 0 auto 36px auto;
      `}
    }

    &::after {
      content: "";
      width: 1px;
      height: calc(100% + 44px);
      position: absolute;
      top: 40px;
      left: calc((29px - 1px) / 2);
      border-left: 1px dotted ${colors.navy__extra_dark};
      z-index: 2;

      ${breakpoint.small`
        width: calc(100% + 24px);
        height: 1px;
        top: calc((29px - 1px) / 2);
        left: calc(((100% - 30px) / 2) + 15px);
        border-top: 1px dotted ${colors.navy__extra_dark};
        border-left: 0;
      `}
    }

    ${breakpoint.small`

    `}

    .stage__pill {
      display: inline-block;
      padding: 24px 40px;
      margin-bottom: 24px;
      border-radius: 200px;
      background-color: ${transparentize(0.8, colors.turquoise__primary)};
      color: ${colors.turquoise__primary};
    }
  }
`

const EthicsApproach = () => {
  const data = [
    "We created an ethics advisory board that brings a broad range of lived experiences to the core of our product design.",
    "We use a form of AI that allows us to effectively monitor and correct imbalances that emerge from the underlying data.",
    "Our internal ethics team works with the board to guide technical implementation to meet our strict ethical standards.",
  ]

  return (
    <StyledEthicsApproach>
      <Container>
        <h2 className="h2 maxi text-sm-center font-weight--700">
          <span className="color--turquoise__primary">A 3-stage</span> Ethics
          Approach
        </h2>
        <div className="row">
          {data.map((item, index) => (
            <div className="col-12 col-sm-4 mb-5 mb-sm-0" key={item}>
              <div className="stage text-sm-center">
                <div>
                  <div className="stage__pill">
                    <p className="h3 font-weight--700">Stage {index + 1}</p>
                  </div>

                  <p className="text--large">{item}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </StyledEthicsApproach>
  )
}

export default EthicsApproach
