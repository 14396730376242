import React, { useState } from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

//  Icons
import IconLinkedIn from "assets/icons/components/team-member-card/icon-linkedin.inline.svg"
import IconArrowRight from "assets/icons/icon-arrow-right--small.inline.svg"
import Modal from "components/modal"

const StyledTeamMemberCard = styled.button`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: center;
  border-radius: 20px;
  transition: all 0.3s ease;
  overflow: hidden;

  &:hover {
    filter: drop-shadow(2px 16px 40px rgba(110, 200, 205, 0.3));
  }

  .card__linked-in {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .card__content {
    width: 100%;
    padding: 72px 24px 50px 24px;
  }

  .card__footer {
    width: 100%;
    align-self: flex-end;
    padding: 24px;
  }
`

const StyledTeamMemberModal = styled.div`
  .separator {
    width: 2px;
    height: 23px;
    border-radius: 1px;
  }
`

const TeamMemberCard = (props) => {
  // Props
  const { fullName, profilePicture, position, company, linkedIn, bio } = props

  // State
  const [showModal, setShowModal] = useState(false)

  // Render options
  const renderOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="h3 font-weight--400 my-4" style={{ fontWeight: 400 }}>
          {children}
        </p>
      ),
    },
  }

  return (
    <>
      <StyledTeamMemberCard
        className="bg--white"
        onClick={() => setShowModal(true)}
      >
        {linkedIn && (
          <a
            href={linkedIn}
            className="card__linked-in d-flex"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconLinkedIn />
          </a>
        )}
        <div className="card__content">
          <GatsbyImage
            image={getImage(profilePicture)}
            className="mb-4"
            imgStyle={{ borderRadius: "50%", overflow: "hidden" }}
            alt=""
          />
          <p className="h2 font-weight--700 mb-1">{fullName}</p>
          <p className="text--normal color--navy__extra_dark">{position}</p>
          {company && (
            <p className="text--normal color--silver__dark">{company}</p>
          )}
        </div>

        <div className="card__footer bg--silver__extra_pale">
          <p className="d-inline-flex align-items-center justify-content-center color--turquoise__primary font-weight--700">
            View Bio
            <IconArrowRight className="ms-1" />
          </p>
        </div>
      </StyledTeamMemberCard>

      <Modal visible={showModal} closeFunction={() => setShowModal(false)}>
        <StyledTeamMemberModal>
          <GatsbyImage
            image={getImage(profilePicture)}
            alt=""
            imgStyle={{
              marginBottom: "40px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          />
          <div className="name d-flex align-items-center">
            <h1 className="h1 mb-1 font-weight--700">
              <span>{fullName}</span>
            </h1>
            {linkedIn && (
              <>
                <div className="separator mx-3 bg--silver__light" />
                <a
                  href={linkedIn}
                  className="d-flex align-items-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconLinkedIn />
                </a>
              </>
            )}
          </div>
          <h4 className="h4 mb-4 color--turquoise__primary font-weight--400">
            {position}
          </h4>

          {renderRichText(bio, renderOptions)}
        </StyledTeamMemberModal>
      </Modal>
    </>
  )
}

TeamMemberCard.propTypes = {
  fullName: PropTypes.string.isRequired,
  profilePicture: PropTypes.shape({}).isRequired,
  position: PropTypes.string.isRequired,
  company: PropTypes.string,
  linkedIn: PropTypes.string,
  bio: PropTypes.shape({ raw: PropTypes.string.isRequired }).isRequired,
}

TeamMemberCard.defaultProps = {
  company: null,
  linkedIn: null,
}

export default TeamMemberCard
