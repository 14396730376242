import React from "react"

// Layout
import Layout from "layouts/layout-primary"

// Components
import SEO from "components/seo/"

// Sections
import Hero from "sections/ethics/hero"
import EthicalConsideration from "sections/ethics/considerations"
import PositiveChange from "sections/ethics/positive-change"
import EthicsApproach from "sections/ethics/ethics-approach"
import EthicsStatement from "sections/ethics/ethics-statement"
import AiEthicsBoard from "sections/ethics/ai-ethics-board"
import EthicsBlogPosts from "sections/ethics/ethics-blog-posts"

const Ethics = () => (
  <Layout showNewsletter={false}>
    <SEO title="Ethics | Arena Analytics" />
    <Hero />
    <EthicalConsideration />
    <PositiveChange />
    <EthicsApproach />
    <EthicsStatement />
    <AiEthicsBoard />
    <EthicsBlogPosts />
  </Layout>
)

export default Ethics
