import React, { useEffect, useRef } from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { transparentize } from "polished"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables"

// Icons
import IconClose from "assets/icons/icon-close.inline.svg"

const StyledModal = styled.div`
  // max-height: -webkit-fill-available;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 24px;
  background-color: ${transparentize(0.5, colors.silver__dark)};
  opacity: ${(props) => (props.visible ? "1" : "0")};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  transition: all 0.3s ease;
  z-index: 9999;

  ${breakpoint.small`
    padding: 48px;
  `}

  .modal__content {
    width: 100%;
    height: 100vh;
    position: relative;
    padding: 0 16px 64px 16px;
    overflow-y: auto;

    ${breakpoint.small`
      max-width: 830px;
      max-height: 708px;
      height: 70%;
      border-radius: 20px;
    `}

    .modal__close {
      position: sticky;
      top: 24px;
      margin-bottom: 64px;
      text-align: right;

      ${breakpoint.medium`
        padding-right: 8px;
      `}
    }

    .content {
      max-width: 642px;
      width: 100%;
      margin: 0 auto;

      p {
        font-family: ${fonts.primary};
      }
    }

    .content__gradient {
      width: 100%;
      height: 64px;
      position: sticky;
      bottom: -64px;
      margin-bottom: -64px;
      background: linear-gradient(
        180deg,
        rgba(244, 246, 247, 0) 0%,
        #f4f6f7 100%
      );
    }
  }
`

const Modal = (props) => {
  // Props
  const { children, visible, closeFunction } = props

  // Hooks
  const ref = useRef()

  /**
   * Closes modal when pressing `Escape` key
   */
  useEffect(() => {
    const handleEscKeydown = (event) => {
      if (event.key === "Escape") {
        closeFunction()
      }
    }

    document.addEventListener("keydown", handleEscKeydown)

    return () => document.removeEventListener("keydown", handleEscKeydown)
  })

  /**
   * Closes modal when clicking outside the `.modal__content` div
   */
  // useEffect(() => {
  //   const handleClick = (event) => {
  //     if (ref.current) {
  //       const { target } = event

  //       if (!ref.current.contains(target) && visible) {
  //         closeFunction()
  //       }
  //     }
  //   }

  //   document.addEventListener("click", handleClick, { passive: true })

  //   return () => document.removeEventListener("click", handleClick)
  // })

  /**
   * Locks scroll when visible
   */
  useEffect(() => {
    if (visible) {
      document.querySelector("html").classList.add("no-scroll")
      document.querySelector("body").classList.add("no-scroll")
    } else {
      document.querySelector("html").classList.remove("no-scroll")
      document.querySelector("body").classList.remove("no-scroll")
    }
  }, [visible])

  if (!visible) {
    return null
  }

  return (
    <StyledModal visible={visible}>
      <div ref={ref} className="modal__content bg--silver__pale">
        <div className="modal__close">
          <button
            type="button"
            className="d-inline-flex align-items-center"
            onClick={closeFunction}
          >
            <IconClose />
          </button>
        </div>

        <div className="content">
          {children}
          <div className="content__gradient" />
        </div>
      </div>
    </StyledModal>
  )
}

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  visible: PropTypes.bool.isRequired,
  closeFunction: PropTypes.func.isRequired,
}

export default Modal
