import React from "react"

// Libraries
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

// Components
import BackgroundImage from "components/background-image/"
import Container from "components/container"

// Utils
// import { colors } from "utils/variables"
import breakpoint from "utils/breakpoints/"

const StyledHero = styled(BackgroundImage)`
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-size: 130%;
  background-position: bottom;

  ${breakpoint.small`
    height: 809px !important;
    &::before,
    &::after {
    background-size: 100% !important;
  }
  `}

  ${breakpoint.medium`
  height: 809px !important;
  `}

  .h1 {
    max-width: 629px;
    margin-top: 50px;
    text-align: center;

    ${breakpoint.small`
    text-align: left;
    margin-top: 108px;
    `}
  }
`

const Hero = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "ethics/background-ethics.png" }) {
        childImageSharp {
          gatsbyImageData(height: 695, quality: 100)
        }
      }
    }
  `)
  return (
    <StyledHero image={image}>
      <Container>
        <h1 className="h1 maxi">Ethics Matter, Because People Matter</h1>
      </Container>
    </StyledHero>
  )
}

export default Hero
