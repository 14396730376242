import React from "react"
// import { Link } from "gatsby"

// Libraries
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

// Utils
import { colors } from "utils/variables"
import breakpoints from "utils/breakpoints"

// Components
import Container from "components/container/"

const StyledPositiveChange = styled.section`
  width: 100%;

  .wrapper {
    background: ${colors.silver__pale};
    padding: 64px 0px;

    ${breakpoints.medium`
      padding: 127px 0 0 0;
    `}

    .wrapper__container {
      max-width: 2200px;
      margin: 0 auto;

      span {
        color: ${colors.turquoise__primary};
      }

      .second-col {
        ${breakpoints.medium`
          margin-top: -200px;
        `}
      }

      .second-col__paragraph {
        ${breakpoints.medium`
          margin-top: 120px;
        `}
      }

      .third-col {
        ${breakpoints.medium`
          margin-top: -150px;
        `}
      }

      .third-col__paragraph {
        ${breakpoints.medium`
          margin-top: 90px;
        `}
      }

      .first-image {
        ${breakpoints.medium`
          width: 80%;
        `}

        ${breakpoints.large`
          max-width: 964px;
          width: 100%;
          margin-top: 74px;
        `}
      }

      .second-image {
        margin: 32px 0;
        width: 90%;
        float: right;

        ${breakpoints.medium`
          width: 55%;
          margin-top: -170px;
        `}

        ${breakpoints.large`
          max-width: 674px;
          width: 100%;
        `}
      }

      .third-image {
        margin-top: 32px;
        width: 90%;

        ${breakpoints.medium`
          width: 60%;
        `}

        ${breakpoints.large`
          max-width: 671px;
          width: 100%;
          margin-bottom: -248px;
          margin-top: -90px;
        `}
      }
    }
  }
`

const PositiveChange = () => (
  <StyledPositiveChange>
    <div className="wrapper">
      <div className="wrapper__container">
        <Container>
          <div className="row justify-content-between">
            <div className="col-12 col-md-6">
              <h1 className="h1 bold mb-3 d-md-none">
                The power of
                <br /> technology to create <span>positive change</span>
              </h1>
              <h1 className="h2 maxi bold mb-3 d-none d-md-block">
                The power of
                <br /> technology to create <span>positive change</span>
              </h1>
            </div>
            <div className="col-12 col-md-6">
              <p className="text--large mb-4">
                We use an array of technologies to help connect employers with
                the right candidates for their specific organizations, while
                helping people discover opportunities where they are most likely
                to find success, stability, and satisfaction.
              </p>
            </div>
          </div>
        </Container>
        <StaticImage
          src="../../assets/images/ethics/hero-image-desktop.png"
          alt=""
          className="first-image"
        />

        <Container>
          <div className="row flex-md-row-reverse">
            <div className="col-12 col-md-6 second-col">
              <h2 className="h2 mt-4 mb-3 d-md-none">
                Our solution seeks to help move the workforce beyond the
                cultural norms that inhibit the movement of talent to the places
                in the economy that need it.{" "}
              </h2>
              <h2 className="h1 mt-4 mb-3 d-none d-md-block">
                Our solution seeks to help move the workforce beyond the
                cultural norms that inhibit the movement of talent to the places
                in the economy that need it.{" "}
              </h2>
            </div>
            <div className="col-12 col-md-6">
              <p className="text--large second-col__paragraph">
                Those factors include discrimination (in all of its forms),
                over-reliance on credentials, limits on imagination, and
                position requirements that exceed the real demands of a given
                job. If we’re going to use technology to create positive change
                for people, we need to ensure that we’re not inadvertently
                causing harm at the same time. That’s where ethics comes into
                play.
              </p>
            </div>
          </div>
        </Container>

        <StaticImage
          src="../../assets/images/ethics/second-image.png"
          alt=""
          className="second-image"
        />
        <Container>
          <div className="row col-12 justify-content-between">
            <div className="col-12 col-md-6 third-col">
              <h2 className="h2 mb-3 mt-3">
                Using AI to help
                <span> decrease bias…</span>
              </h2>
              <p className="text--large">
                We compare historical data from employers and job seekers. We
                radically reduce bias and the relative importance of
                credentials. The idea is to keep the focus on thriving rather
                than meeting a set of mechanical requirements.
              </p>
            </div>
            <div className="col-12 col-md-6 third-col__paragraph">
              <h2 className="h2 mt-4 mb-2 ai-help-title">
                …while guarding <span>against bias in AI</span>
              </h2>
              <p className="text--large">
                This approach is fraught with the potential to seriously
                malfunction. That is the nature of AI in its current early
                stage. The risks range from perpetuating discrimination to
                unfairly limiting access to employment.
              </p>
            </div>
          </div>
        </Container>
        <StaticImage
          src="../../assets/images/ethics/third-image.png"
          alt=""
          className="third-image"
        />
      </div>
    </div>
  </StyledPositiveChange>
)

export default PositiveChange
