import React from "react"

// Libraries
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import Button from "components/button"
import { graphql, useStaticQuery } from "gatsby"

const StyledEthicsStatement = styled.section`
  padding: 64px 0;

  ${breakpoint.medium`
    padding: 160px 0;
  `}

  .statement__card {
    border-radius: 16px;
    overflow: hidden;

    .card__content {
      height: 100%;
      padding: 32px;

      ${breakpoint.medium`
        padding: 32px 84px;
      `}
    }
  }
`

const EthicsStatement = () => {
  const {
    contentfulEthics: { ethicsStatement: statement },
  } = useStaticQuery(graphql`
    query {
      contentfulEthics(title: { eq: "Ethics" }) {
        ethicsStatement {
          file {
            url
          }
        }
      }
    }
  `)

  return (
    <StyledEthicsStatement className="bg--navy__extra_dark color--white">
      <Container>
        <div className="statement__card bg--white color--navy__extra_dark">
          <div className="row">
            <div className="col-12 col-sm-6 bg--silver__pale">
              <div className="card__content d-flex align-items-center">
                <div>
                  <h2 className="h1 mb-4 font-weight--700">
                    Read Our Ethics
                    <br />
                    Statement
                  </h2>
                  <Button
                    type="link"
                    level="primary"
                    to={statement.file.url}
                    external
                  >
                    View Statement
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <StaticImage
                src="../../assets/images/ethics/ethics-statement-image.png"
                width="448"
                height="320"
                alt=""
              />
            </div>
          </div>
        </div>
      </Container>
    </StyledEthicsStatement>
  )
}

export default EthicsStatement
